/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';

import { observer } from 'mobx-react';

import { css, Table, TableBody, TableContainer, TableHead, TableRow, TableRowProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { TableType } from 'utils/constants';
import { ErrorHistory, PipelineLogError } from 'utils/types';

import { StyledTableCell } from './StyledTableCell';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export type ErrorTableRowRendererProps<T extends PipelineLogError | ErrorHistory> = TableRowProps & {
  errorInfo: T;
  isErrorHistory?: boolean;
  onErrorResolved?: () => void;
};

export interface ARMFlowTableProps<T extends PipelineLogError | ErrorHistory> extends ComponentPropsWithoutRef<'div'> {
  tableType: TableType;
  tableHeaders: ReactNode[];
  errorList: T[];
  RowRenderer: (props: ErrorTableRowRendererProps<T>) => React.ReactElement;
  onErrorResolved?: ErrorTableRowRendererProps<T>['onErrorResolved'];

  loading?: boolean;
  isErrorHistory?: boolean;
  extraComponent?: ReactNode;
}

const ErrorTable = observer(
  <T extends PipelineLogError | ErrorHistory>({
    tableType,
    tableHeaders: headers,
    errorList,
    RowRenderer,
    onErrorResolved,
    loading = false,
    isErrorHistory,
    extraComponent,
    ...props
  }: ARMFlowTableProps<T>): React.ReactElement | null => {
    const { className } = props;

    const classes = useStyles(props);

    const errorsExist = !!errorList.length;
    // TODO: If showActions, add "Actions" cell to rendered table

    return (
      <div className={clsx(classes.root, className)}>
        {extraComponent}
        <TableContainer>
          {loading ? (
            <LoadingSpinner spacing={5} />
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    {headers.map((content, idx) => (
                      <StyledTableCell key={idx}>{content}</StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {errorsExist && (
                  <TableBody key="log-table-body">
                    {errorList.map((value, idx) => (
                      <RowRenderer
                        key={`errorInfo-item-${idx}`}
                        errorInfo={value}
                        isErrorHistory={isErrorHistory}
                        onErrorResolved={onErrorResolved}
                      />
                    ))}
                  </TableBody>
                )}
              </Table>
              {!errorsExist && <div css={noLogsStyle}>no errors found</div>}
            </>
          )}
        </TableContainer>
      </div>
    );
  }
);

export default ErrorTable;

const noLogsStyle = css`
  background-color: ${grey[900]};
  text-align: center;
  padding: 2rem 0;
  text-transform: capitalize;
`;
