import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, Stack, Switch } from '@mui/material';

import { useServices } from 'services';

import { RefreshButton } from 'components/common/RefreshButton';
import { TableType } from 'utils/constants';
import { PipelineErrorHistoryResponse } from 'utils/types';

import ErrorHistoryTableRow from '../ErrorLogTable/ErrorHistoryTableRow';
import ErrorTable from '../ErrorLogTable/ErrorTable';
import PipelineErrorTableRow from '../ErrorLogTable/PipelineErrorTableRow';
import SystemErrorTableRow from '../ErrorLogTable/SystemErrorTableRow';
import { TableHeader } from '../ErrorLogTable/TableHeader';
import { ModalTitle } from './ModalTitle';
import { ModalDialogProps } from './props';
import { useModalStyles } from './styles';

export const GeneralProcessErrorsModal = observer((props: ModalDialogProps) => {
  const classes = useModalStyles(props);
  const { modalInfo, onModalClose } = props;
  const { id: modalId, title, subtitle, errorTableType, customModalData } = modalInfo;

  const { processingService } = useServices();
  const { pipelineErrorData, systemErrorData } = processingService;

  const [isLoading, setIsLoading] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

  const refreshWithLoading = async (refreshFunc: () => void) => {
    setIsLoading(true);
    await refreshFunc();
    setIsLoading(false);
  };

  const handleRefreshSystemErrors = useCallback(
    (openErrorsOnly: boolean = !showAllErrors) =>
      refreshWithLoading(() => processingService.fetchSystemErrors({ skipLoading: true, openErrorsOnly })),
    [processingService, showAllErrors]
  );

  const handleToggleShowAllSystemErrors = useCallback(
    (_: ChangeEvent<HTMLInputElement>, fetchAllErrors: boolean) => {
      setShowAllErrors(fetchAllErrors);
      handleRefreshSystemErrors(!fetchAllErrors); // Need to pass `openErrorsOnly` flag to avoid using memoized `showAllErrors` when refreshing
    },
    [handleRefreshSystemErrors]
  );

  const TitleAdornmentComponent = useMemo(() => {
    switch (errorTableType) {
      case TableType.ErrorHistory: {
        if (!customModalData) return null;

        const { inc_url } = customModalData as Pick<PipelineErrorHistoryResponse, 'inc_url'>;
        if (!inc_url) return null;

        return (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
            <span>
              <Button href={inc_url} target="_blank" endIcon={<OpenInNewIcon />} style={{ textTransform: 'unset' }}>
                View INC
              </Button>
            </span>
          </Stack>
        );
      }

      case TableType.SystemErrors: {
        return (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
            <FormControlLabel
              control={<Switch onChange={handleToggleShowAllSystemErrors} checked={showAllErrors} />}
              label="Show All Errors"
            />
            <RefreshButton tooltipText="Refresh table" placement="left" onClick={() => handleRefreshSystemErrors()} />
          </Stack>
        );
      }

      default:
        return null;
    }
  }, [customModalData, errorTableType, handleRefreshSystemErrors, handleToggleShowAllSystemErrors, showAllErrors]);

  const [RowRenderer, tableHeaders, errorList, onErrorResolved] = useMemo(() => {
    switch (errorTableType) {
      case TableType.ErrorHistory:
        // TODO: (2024/06/25 Elvis) This case is never reached due to rendering `PipelineErrorHistoryModal` in `ModalsView`. Bring in tabs and custom adornment renderers to reduce duplicate code
        return [PipelineErrorTableRow, TableHeader[errorTableType], pipelineErrorData?.errorList];

      case TableType.PipelineError:
        // TODO: (2024/07/09 Elvis) This case made obsolete now that ModalsView renders `PipelineErrorHistoryModal` for pipeline errors (which also renders `PipelineErrorTableRow`)
        return [PipelineErrorTableRow, TableHeader[errorTableType], pipelineErrorData?.errorList];

      case TableType.SystemErrors:
        return [
          SystemErrorTableRow,
          TableHeader[errorTableType],
          systemErrorData?.errorList,
          handleRefreshSystemErrors,
        ];

      default:
        return [ErrorHistoryTableRow, [], null];
    }
  }, [errorTableType, handleRefreshSystemErrors, pipelineErrorData?.errorList, systemErrorData?.errorList]);

  return (
    <Dialog
      open={true}
      className={classes.log}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      onClose={(e) => onModalClose && onModalClose(modalId)}
    >
      {/* Title */}
      <ModalTitle modalTitle={title} modalSubtitle={subtitle} adornment={TitleAdornmentComponent} />

      {/* Text */}
      <DialogContent>
        <ErrorTable
          loading={isLoading}
          tableType={errorTableType ?? TableType.ErrorHistory}
          tableHeaders={tableHeaders}
          errorList={errorList ?? []}
          RowRenderer={RowRenderer as never} // TODO: need to fix type here
          onErrorResolved={onErrorResolved}
        />
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Button onClick={(e) => onModalClose && onModalClose(modalId)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
});
