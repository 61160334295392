import React, { ReactNode, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import {
  Button,
  css,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Stack,
  styled,
  TableContainer,
  Theme,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-noconflict/mode-text';
import 'ace-builds/src-noconflict/theme-ambiance';
import 'ace-builds/src-noconflict/theme-github';
import clsx from 'clsx';
import { DateTime } from 'luxon';

import { useServices } from 'services';

import { LoadingSpinner } from 'components/common/LoadingSpinner';
import { Text } from 'components/styles';
import { UseStyles } from 'styles/utilityTypes';
import { ProcessType } from 'utils/constants';
import { PipelineIdentifier } from 'utils/types';
import { generatePipelineStandardModalTitle } from 'utils/utils';

import InfoViewerBody from './infoViewer/InfoViewerBody';
import ViewComlogButton from './infoViewer/ViewComlogButton';
import { ModalTitle } from './modals/ModalTitle';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dialog: {
    // height: '100%',
  },
  themeSelect: {
    paddingBottom: theme.spacing(2),
  },
}));

export interface InfoViewerParams {
  /** @deprecated Unused. remove property */
  open: boolean;
  title?: string | null;
  processName: string;
  dataDate: DateTime;
  site: string;
  facility: string;
}

export interface InfoViewerProps extends UseStyles<typeof useStyles> {
  className?: string;
}

const NoDataText = 'No data found.';
const ClosingText = 'Closing...';

const InfoViewer = observer((props: InfoViewerProps): React.ReactElement | null => {
  const classes = useStyles(props);
  const [loading, setLoading] = useState(true);
  const [emptyText, setEmptyText] = useState('No data found.');
  const { actionBarService, processingService } = useServices();

  const { className } = props;
  const { infoViewerParams, infoViewerData } = processingService;

  if (!infoViewerParams) return null;
  const { processName, site, facility, dataDate } = infoViewerParams;

  const open = actionBarService.openViewers.infoViewer;
  const pipelineId: PipelineIdentifier = { process_name: processName, site, facility };
  const pipelineModalTitle = generatePipelineStandardModalTitle(pipelineId);
  const dateString = dataDate.toISODate();

  useEffect(() => {
    if (!open) return;
    if (!loading) resetContents();

    processingService
      .fetchDataPipelineInfo({ process_name: processName, site, facility, date: dateString })
      .then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setEmptyText(ClosingText);
    processingService.clearInfoViewerData();
    actionBarService.setInfoViewerOpen(false);
  };

  const resetContents = () => {
    setLoading(true);
    setEmptyText(NoDataText);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Dialog className={classes.dialog} open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <ModalTitle modalTitle="Pipeline Information" modalSubtitle={`${pipelineModalTitle} - ${dateString}`} />

        <DialogContent>
          <Stack justifyContent="center">
            {loading ? (
              <LoadingSpinner spacing={0} />
            ) : infoViewerData ? (
              <Stack rowGap={'2rem'}>
                {Object.values(ProcessType).map(
                  (processType, idx) =>
                    infoViewerData[processType] && (
                      <InfoViewerBody key={idx} pipelineId={{ ...pipelineId, processType }} dataDate={dataDate} />
                    )
                )}
              </Stack>
            ) : (
              <>
                <Text textAlign="center">{emptyText}</Text>
              </>
            )}
          </Stack>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'space-between' }}>
          {!infoViewerData?.VAP ? (
            <ViewComlogButton pipelineId={pipelineId} logfileParams={{ date: dateString }} />
          ) : (
            <div /> // render empty div to ensure "close" button aligns to right
          )}
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default InfoViewer;

// const RawFileBundleSection = () => {
//   return (
//     <div>
//       <Text>Raw File Bundle</Text>
//       {/* <DataGrid/> */}
//     </div>
//   );
// };

export interface InfoViewerTableProps extends React.ComponentPropsWithoutRef<'div'> {
  title: string;
  headers: (string | null)[];
  rows: ReactNode[][];
}

const _InfoViewerTable = ({ title, headers, rows, ...props }: InfoViewerTableProps) => {
  return (
    <div {...props}>
      <Text paddingTop={'1rem'}>{title}</Text>
      <TableContainer component={Paper}>
        <Table>
          <InfoTableHeader>
            <TableRow>
              {headers.map((value, idx) => (
                <TableCell key={idx}>{value}</TableCell>
              ))}
            </TableRow>
          </InfoTableHeader>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                {row.map((value, colIndex) => (
                  <TableCell key={colIndex}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export const InfoViewerTable = styled(_InfoViewerTable)(
  ({ theme }) => css`
    /* width: 80%; */

    justify-items: center;
    padding: 0 4rem;

    .${tableCellClasses.body} {
      background-color: ${theme.palette.grey[900]};
    }
  `
);

export const InfoTableHeader = styled(TableHead)`
  .t {
    padding: 0;
  }
`;
