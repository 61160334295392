import { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { TableCellProps, TableRow } from '@mui/material';

import { capitalize } from 'lodash';

import { useServices } from 'services';

import { ErrorHistoryType } from 'utils/constants';
import { PipelineIdentifier, PipelineLogError } from 'utils/types';

import ActionsCell from './ActionsCell';
import { ErrorTableRowRendererProps } from './ErrorTable';
import LongText from './LongText';
import { StyledTableCell } from './StyledTableCell';

const ColumnProps: Array<TableCellProps | null> = [null, null, null, null, null, null, null, null, null, null];

type SystemErrorTableRowProps = ErrorTableRowRendererProps<PipelineLogError>;

/** @todo Very similar code as `PipelineErrorTableRow`. Find way to consolidate (maybe use `TableHeader` somehow) */
const SystemErrorTableRow = observer(({ errorInfo, onErrorResolved, ...props }: SystemErrorTableRowProps) => {
  const {
    id: sys_id,
    proc_type,
    processing_id,
    error_level,
    error_code: parsedErrorCodes,
    error_message,
    latest_occurrence,
    state,
    logfile_keys,

    proc_name: process_name,
    site,
    fac: facility,
  } = errorInfo;
  const pipelineId: PipelineIdentifier = { process_name, site, facility };
  const { processingService } = useServices();

  // TODO: move handlers executed in `ActionLink` into a hook
  const handleResolveError = (comment: string) => {
    processingService
      .resolveError({ errorId: sys_id, errorType: ErrorHistoryType.System, comment, skipLoading: true })
      .then(() => {
        if (onErrorResolved) {
          onErrorResolved();
        }
      });
  };

  const rowDataList: ReactNode[] = [
    proc_type,
    capitalize(error_level),
    <pre key="rowDataList-error_code" title={parsedErrorCodes}>
      {parsedErrorCodes}
    </pre>,
    <LongText key="rowDataList-error_message" text={error_message} />,
    latest_occurrence,
    state,
    <ActionsCell
      key="rowDataList-actions"
      state={state}
      showErrorHistory={false}
      inc_url={null}
      sys_id={sys_id}
      logfileKeys={logfile_keys}
      procType={proc_type}
      processingId={processing_id}
      pipelineId={pipelineId}
      resolveErrorHandler={handleResolveError}
    />,
  ];

  return (
    <TableRow {...props}>
      {rowDataList.map((content, idx) => (
        <StyledTableCell key={idx} {...ColumnProps[idx]}>
          {content}
        </StyledTableCell>
      ))}
    </TableRow>
  );
});

export default SystemErrorTableRow;
