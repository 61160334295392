/** @jsxImportSource @emotion/react */
import { observer } from 'mobx-react-lite';

import ViewColumnOutlinedIcon from '@mui/icons-material/ViewColumnOutlined';
import { css, IconButton, Tooltip } from '@mui/material';
import { green, grey } from '@mui/material/colors';

import { useServices } from 'services';

import { RefreshButton } from 'components/common/RefreshButton';
import ActionMenu from 'components/menus/ActionMenu';
import { transparentWhite } from 'components/styles';
import { useContextActionMenu } from 'hooks/useContextActionMenu';
import {
  ActionMenuItems,
  AnchorPoints,
  DEFAULT_LABEL_WIDTH,
  ErrorHistoryType,
  MINIMUM_LABEL_WIDTH,
} from 'utils/constants';

import { SystemErrorButton } from '../SystemErrorButton';
import { TimelineGridCellProps } from './TimelineGridCell';

export const TimelineScrollSyncCorner = observer(({ gridCellProps }: TimelineGridCellProps) => {
  const { key, style } = gridCellProps;
  const { processingService, actionBarService } = useServices();

  const { actionMenuOpen, anchorEl, handleContextMenu, closeContextMenu } = useContextActionMenu();

  const { visualToggles } = actionBarService;
  const { hasSystemError } = processingService;
  const autoRefresh = visualToggles['auto-refresh'].active;

  const refreshButtonColor = autoRefresh ? green[800] : undefined;
  const tooltipText = autoRefresh ? 'Auto-Refresh ON' : 'Refresh Data';
  const disabledManualRefresh = autoRefresh ? true : false;

  const showLabelWidthReset = actionBarService.labelWidth !== DEFAULT_LABEL_WIDTH;

  return (
    <div key={key} style={style} css={cornerCellStyle}>
      <div css={labelStyle}>
        {/* Refresh button */}
        <RefreshButton
          tooltipText={tooltipText}
          color={refreshButtonColor}
          onClick={() => processingService.refresh()}
          placement="top"
          disabled={disabledManualRefresh}
          buttonCss={iconButtonStyle}
          tooltipProps={{ arrow: true, disableInteractive: false }}
        />

        <span
          css={[!hasSystemError && hoveredStyle, actionMenuOpen && contextMenuOpenStyle]}
          onContextMenu={(event) => !hasSystemError && handleContextMenu(event)}
        >
          {/* Cell Title */}
          Data Pipeline
          {/* System error button */}
          {hasSystemError && <SystemErrorButton />}
        </span>
      </div>

      {showLabelWidthReset && (
        <Tooltip title={`Revert to default width (${DEFAULT_LABEL_WIDTH}px)`} arrow placement="top">
          <IconButton css={iconButtonStyle} onClick={() => actionBarService.resetLabelWidth()}>
            <ViewColumnOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}

      {anchorEl && (
        <ActionMenu
          {...AnchorPoints.BottomLeft}
          hideDivider
          anchorEl={anchorEl}
          errorHistoryType={ErrorHistoryType.System}
          onClose={closeContextMenu}
          hideActions={{
            [ActionMenuItems.REFRESH]: true,
            [ActionMenuItems.INFO]: true,
            [ActionMenuItems.VIEW_PROCESS_LOG]: true,
            [ActionMenuItems.VIEW_SLURM_LOG]: true,
            [ActionMenuItems.VIEW_SLURM_BATCH_SCRIPT]: true,
            [ActionMenuItems.VIEW_ARMFLOW_PROCESS_CONFIG]: true,
            [ActionMenuItems.VIEW_BUNDLE_LOG]: true,
            [ActionMenuItems.ADD_COMMENT]: true,
            [ActionMenuItems.RUN_PROCESS]: true,
            [ActionMenuItems.PRUNE_DATA]: true,
            [ActionMenuItems.RELEASE_DATA]: true,
          }}
        />
      )}
    </div>
  );
});

const labelStyle = css`
  display: grid;
  max-width: ${MINIMUM_LABEL_WIDTH}px;
  grid-template-columns: 1fr 11fr;
  /* grid-template-columns: 1fr 11fr auto; */
  /* grid-column-gap: 0.25rem; */
  align-items: center;
`;

const cornerCellStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
`;

const iconButtonStyle = css`
  color: ${grey[300]};
`;

const hoveredStyle = css`
  :hover {
    background-color: ${transparentWhite(0.05)};
  }
`;

const contextMenuOpenStyle = css`
  background-color: ${transparentWhite(0.1)};
`;
