import { action, makeObservable, observable, transaction } from 'mobx';

import autoBind from 'auto-bind';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { capitalize, isEqual } from 'lodash';
import { DateTime } from 'luxon';

import { InfoViewerParams } from 'components/dialogs/InfoViewer';
import Filters from 'models/Filters';
import PruneDataForm, { PruneDataFormFields } from 'models/PruneDataForm';
import ReleaseDataForm, { ReleaseDataFormFields } from 'models/ReleaseDataForm';
import RunBundlerForm, { RunBundlerFormFields } from 'models/RunBundlerForm';
import RunProcessForm, { RunProcessFormFields } from 'models/RunProcessForm';
import Timeline, { sortPipelines, TimelineHash, TimelineJSON } from 'models/Timeline';
import { ArmflowFormFieldsMap, ArmflowFormModelMap } from 'models/types';
import {
  API,
  AppState,
  ARMFlowForms,
  AUTO_REFRESH_DELAY,
  EMPTY_LOG_VIEWER_DATA,
  FileDownloadTypes,
  MAX_DATE,
  MIN_DATE,
  Mode,
  ProcessType,
  RefreshReason,
  RunMode,
  RunType,
  SettingsVisualToggleKey,
  SortOrder,
  TableType,
} from 'utils/constants';
import {
  CurrentRunsInfoResponse,
  ErrorIdParameters,
  EWOUrlResponse,
  FetchAllOpenErrorsArgs,
  FetchLogFileOptions,
  FetchSystemErrorsArgs,
  HasSystemErrorResponse,
  LocationsListResponse,
  LogfileParameters,
  LogViewerData,
  NonReleasedProcessingIdsResponse,
  PipelineErrorArgs,
  PipelineErrorData,
  PipelineErrorHistoryModalCustomData,
  PipelineErrorParams,
  PipelineErrorResponse,
  PipelineIdentifier,
  ProcessesMap,
  ProcessingIntervalParameters,
  ProcessStatesData,
  PrunableDatesParams,
  PrunableDatesResponse,
  ResolveErrorArgs,
  ResolveErrorParams,
  ResolveErrorResponse,
  RunInfoResponse,
  RunModeChangePayload,
  RunModeDetailsResponse,
  StopProcessResponse,
  SystemErrorData,
  SystemErrorHistoryResponse,
  UpdatePipelineErrorsArgs,
  UsersListResponse,
} from 'utils/types';
import {
  createFilterURL,
  filterSitesFromLocations,
  generatePipelineInfoFromId,
  generatePipelineStandardModalTitle,
} from 'utils/utils';

import ActionBarService from './ActionBarService';
import AdiApiService from './AdiApiService';
import AuthService from './AuthService';
import ModalsService, { ModalInfo } from './ModalsService';
import StorageService from './StorageService';

type ProcessingStatus = {
  state: AppState;
  message: string;
};

/** @deprecated Remove 'mode' concept */
type FilterMap = Record<Mode, Filters>;

type ProcessingServiceProps = {
  actionBarService: ActionBarService;
  adiApiService: AdiApiService;
  authService: AuthService;
  modalsService: ModalsService;
  storageService: StorageService;
};

export default class ProcessingService {
  private actionBarService: ActionBarService;
  private adiApiService: AdiApiService;
  private authService: AuthService;
  private modalsService: ModalsService;
  private storageService: StorageService;
  private _autoRefreshInterval: null | ReturnType<typeof setInterval> = null;

  private _dataLastRefreshed: DateTime | null = null;
  private _dataHash: TimelineHash['hash'] | null = null;

  // Application state settings
  /** @deprecated Remove 'mode' concept */
  @observable mode: Mode = Mode.EVENT;
  // TODO: May need to make status a map of ProcessingStatus
  @observable status: ProcessingStatus = {
    state: AppState.IDLE,
    message: '',
  };

  @observable processNames: string[] = [];
  @observable processTypes: Record<string, string> = {};
  @observable processesMap: ProcessesMap = {};
  @observable locations: string[] = [];
  @observable locationsFacilitiesOnly: string[] = [];
  @observable users: string[] = [];
  @observable filters: FilterMap;
  @observable sortBy: SortOrder = SortOrder.LOCATION;
  @observable hasSystemError: boolean = false;

  // Selections
  @observable selectedPipelineId: PipelineIdentifier | null = null;

  // Data objects
  @observable timelineData = new Timeline(DateTime.now(), DateTime.now(), 0, []);
  @observable infoViewerParams: Omit<InfoViewerParams, 'open'> | null = null;
  @observable infoViewerData: RunInfoResponse | null = null;
  @observable logViewerData: LogViewerData = EMPTY_LOG_VIEWER_DATA;
  @observable pipelineErrorData: PipelineErrorData | null = null;
  @observable pipelineErrorPipelineId: PipelineIdentifier | null = null;
  @observable systemErrorData: SystemErrorData | null = null;
  @observable processStatesData: ProcessStatesData | null = null;

  @observable runProcessDialogForm: RunProcessForm = new RunProcessForm();
  @observable releaseDataDialogForm: ReleaseDataForm = new ReleaseDataForm();
  @observable pruneDataDialogForm: PruneDataForm = new PruneDataForm();
  @observable runBundlerDialogForm: RunBundlerForm = new RunBundlerForm();

  constructor({ actionBarService, adiApiService, authService, modalsService, storageService }: ProcessingServiceProps) {
    this.actionBarService = actionBarService;
    this.adiApiService = adiApiService;
    this.authService = authService;
    this.modalsService = modalsService;
    this.storageService = storageService;

    // Initialize filters either from query string or StorageService (if either are set, otherwise default)
    const { savedFilter } = this.storageService;
    this.filters = {
      // TODO (2022-12-15, Elvis): we don't need multiple modes anymore since event-driven/manual/reprocessing are all in one dashboard
      [Mode.EVENT]: new Filters({ mode: Mode.EVENT, filters: savedFilter, useUrlFilters: true }),
      [Mode.MANUAL]: new Filters({ mode: Mode.MANUAL, filters: savedFilter, useUrlFilters: true }),
      [Mode.REPROCESSING]: new Filters({ mode: Mode.REPROCESSING, filters: savedFilter, useUrlFilters: true }),
    };

    // Auto-save filters and settings to storage when app loads
    this.saveFiltersToStorage(this.currentModeFilters);

    // Set MobX observables
    makeObservable(this);

    // Initial data fetch
    this.refresh(RefreshReason.Manual);

    // Temporary setInterval to auto-refresh data (gets cancelled if needed during timeline refresh)
    this.activateAutoRefresh();

    // Automatically bind `this` for all class methods
    autoBind(this);
  }

  get currentModeFilters(): Filters {
    return this.filters[this.mode];
  }

  get pipelineCount(): { current: number; total: number } {
    return {
      current: this.timelineData.timelines.length,
      total: this.timelineData.total_pipelines,
    };
  }

  findPipelineInfo(pipelineId: PipelineIdentifier, processType: ProcessType) {
    const { process_name, site, facility } = pipelineId;
    return this.timelineData.timelines.find(
      (timeline) =>
        timeline.process === process_name &&
        timeline.site === site &&
        timeline.facility === facility &&
        timeline.type === processType
    );
  }

  private activateAutoRefresh = () => {
    // Only trigger new setInterval() if one doesn't exist
    if (!this._autoRefreshInterval) {
      this._autoRefreshInterval = setInterval(() => {
        this.fetchTimelines(this.mode, RefreshReason.AutoRefresh);
        this.loadSystemError(RefreshReason.AutoRefresh);
      }, AUTO_REFRESH_DELAY);
    }
  };

  private deactivateAutoRefresh = () => {
    // Only clear new setInterval() if one exists
    if (this._autoRefreshInterval) {
      clearInterval(this._autoRefreshInterval);
      this._autoRefreshInterval = null;
    }
  };

  @action
  setMode = (new_mode: Mode): void => {
    this.mode = new_mode;
  };

  @action
  setSelectedPipelineId = (pipelineId: ProcessingService['selectedPipelineId']) => {
    this.selectedPipelineId = pipelineId;
  };

  @action
  clearSelectedPipelineId = () => {
    this.setSelectedPipelineId(null);
  };

  @action
  setProcessesMap = (processesMap: ProcessingService['processesMap']) => {
    this.processesMap = processesMap;
  };

  @action
  setProcessNames = (processNames: ProcessingService['processNames']) => {
    this.processNames = processNames;
  };

  @action
  setProcessTypes = (processTypes: Record<string, string>) => {
    this.processTypes = processTypes;
  };

  @action
  setLocations = (locations: ProcessingService['locations']) => {
    this.locations = locations;
  };

  @action
  setUsers = (users: ProcessingService['users']) => {
    this.users = users;
  };

  @action
  setHasSystemError = (hasSystemError: boolean) => {
    this.hasSystemError = hasSystemError;
  };

  @action
  setSortOrder = (new_sort: SortOrder): void => {
    if (Object.values(SortOrder).includes(new_sort)) {
      this.sortBy = new_sort;
      this.timelineData.timelines = sortPipelines(this.timelineData.timelines, this.sortBy);
    }
  };

  /** Info Viewer actions */
  @action
  setInfoViewerParams(info: ProcessingService['infoViewerParams']) {
    this.infoViewerParams = info;
  }

  @action
  clearInfoViewerParams() {
    this.setInfoViewerParams(null);
  }

  @action
  setInfoViewerData(info: ProcessingService['infoViewerData']) {
    this.infoViewerData = info;
  }

  @action
  clearInfoViewerData() {
    this.setInfoViewerData(null);
  }

  /** Log Viewer actions */
  @action
  setLogViewerPipelineId(pipelineId: LogViewerData['pipelineId']) {
    this.logViewerData.pipelineId = pipelineId;
  }

  @action
  setLogViewerData(logViewerData: ProcessingService['logViewerData']) {
    this.logViewerData = logViewerData;
  }

  /** Error History actions */
  @action
  setPipelineErrorPipelineId(pipelineId: ProcessingService['pipelineErrorPipelineId']) {
    this.pipelineErrorPipelineId = pipelineId;
  }
  @action
  setPipelineErrorData(pipelineErrorData: ProcessingService['pipelineErrorData']) {
    this.pipelineErrorData = pipelineErrorData;
  }
  @action
  setSystemErrorData(systemErrorData: ProcessingService['systemErrorData']) {
    this.systemErrorData = systemErrorData;
  }
  @action
  clearPipelineErrorData() {
    this.setPipelineErrorData(null);
  }
  @action
  clearSystemErrorData() {
    this.setSystemErrorData(null);
  }

  /** Process States actions */
  @action
  setProcessStatesData(processStatesData: ProcessingService['processStatesData']) {
    this.processStatesData = processStatesData;
  }
  @action
  clearProcessStatesData() {
    this.setProcessStatesData(null);
  }

  @action
  refresh(reason: RefreshReason = RefreshReason.Programmatic) {
    // Fetch pipeline data
    this.fetchTimelines(this.mode, reason);

    // Fetch process map & list
    this.loadProcesses();

    // Fetch location list
    this.loadLocations();

    // Fetch user list
    this.loadUsers();

    // Check for system errors
    this.loadSystemError();
  }

  @action
  applyFilters(reason: RefreshReason = RefreshReason.Programmatic) {
    const filters = this.currentModeFilters;
    filters.resetChangeCounter();
    this.saveFiltersToStorage(filters); // Only save filters to StorageService when applied
    this.refresh(reason);
  }

  @action
  saveFiltersToStorage(filters: Filters) {
    // Save filters to StorageService
    this.storageService.setFilters(filters.toJSON());
  }

  @action
  isValidDateRange(dateRange: [DateTime | null, DateTime | null], nullForbidden: boolean = true) {
    const [startDate, endDate] = dateRange;

    // Check if either date is NULL
    if (nullForbidden && (!startDate || !endDate)) return false;

    if (!!startDate && !!endDate) {
      // Check if either date is invalid
      if (isNaN(startDate.year) || isNaN(endDate.year)) return false;

      // Check if start date is before end date
      if (startDate > endDate) return false;

      // Check that neither date is less than our defined minimum or maximum
      if (startDate.year < MIN_DATE.year || MAX_DATE.year < startDate.year) {
        return false;
      }
      if (endDate.year < MIN_DATE.year || MAX_DATE.year < endDate.year) {
        return false;
      }
    }

    return true;
  }

  @action
  fetchDataPipelineInfo = (infoParams: ProcessingIntervalParameters) => {
    const { process_name, site, facility, date } = infoParams;
    const location = `${site} ${facility}`;
    const url = `${API.PIPELINE_RUN_INFO}?process_name=${process_name}&location=${location}&date=${date}`;

    return this.adiApiService.axios
      .get(url)
      .then((response: AxiosResponse) => {
        const data: RunInfoResponse = response.data;
        this.setInfoViewerData(data);
      })
      .catch((error: unknown) => {
        this.adiApiService.handleError(error, { id: 'dataPipeline-info-retrieval-error' });
        this.setInfoViewerData(null);
      });
  };

  @action
  fetchRunningProcessInfo = (pipelineId: PipelineIdentifier) => {
    const { process_name, site, facility } = pipelineId;
    const location = `${site} ${facility}`;
    const params = { process_name, location };

    return this.adiApiService.axios
      .get(API.CURRENT_PIPELINE_RUNS, { params })
      .then((response: AxiosResponse) => {
        const data: CurrentRunsInfoResponse = response.data;
        return data;
      })
      .catch((error: unknown) => {
        this.adiApiService.handleError(error, {
          id: 'dataPipeline-current-runs-retrieval-error',
          message: 'Could not retrieve information for current data pipeline runs.',
        });
        return null;
      });
  };

  @action
  fetchPipelineError = ({
    pipelineId,
    getHistory = false,
    defaultTab = this.selectedPipelineId?.processType,
  }: PipelineErrorArgs) => {
    const loadingText = getHistory ? 'Fetching pipeline error history...' : 'Fetching pipeline errors...';
    this.updateStatus(AppState.LOADING, loadingText);
    this.clearPipelineErrorData();

    const pipelineInfo = generatePipelineInfoFromId(pipelineId);
    const {
      process_name: processName,
      processType,
      processingId, // TODO: (2024-02-28, Elvis) Eventually will need to update processingId generation so it doesn't always default to regular
      location,
      pipelineName,
    } = pipelineInfo;

    const params: PipelineErrorParams = {
      processName,
      location,
      processType,
      processingId,
      openErrorsOnly: !getHistory,
    };
    return this.adiApiService.axios
      .get(API.QUERY_ERROR, { params })
      .then((response: AxiosResponse) => {
        const data: PipelineErrorResponse = response.data;
        const { process_name, /* site, facility, */ errors, inc_url } = data;
        this.setPipelineErrorPipelineId(pipelineId);

        // Bubble up unassigned values to the top of the list, if not error history
        const sortedErrorList = getHistory ? errors : sortByUnassigned(data.errors); // assigned_to is empty string if no person is assigned

        // Create and set pipeline error data
        const pipelineErrorData: PipelineErrorData = getHistory
          ? {
              errorList: sortedErrorList,
              inc_url: inc_url ?? null,
              isErrorHistory: true,
            }
          : {
              errorList: sortedErrorList,
              isErrorHistory: false,
            };
        this.setPipelineErrorData(pipelineErrorData);

        // Instantiate miscellaneous modal information
        const pipelineModalTitle = generatePipelineStandardModalTitle(pipelineId);
        const customModalData: PipelineErrorHistoryModalCustomData | undefined = getHistory
          ? { defaultTab }
          : undefined;

        // Push new modal info to trigger error table dialog
        const modalInfo: ModalInfo = getHistory
          ? {
              id: `pipeline_error_history-${pipelineName}`,
              title: `Pipeline Error History`,
              // TODO: see processingId TODO at declaration
              subtitle: processType && processingId ? `${pipelineModalTitle} - ${processingId}` : pipelineModalTitle,
              state: AppState.PIPELINE_ERROR_HISTORY,
              errorTableType: TableType.ErrorHistory,
              customModalData,
            }
          : {
              id: `error_log-${process_name}_${location}_${processType}`,
              title: `Processing Errors`,
              subtitle: pipelineModalTitle,
              state: AppState.PIPELINE_ERROR,
              errorTableType: TableType.PipelineError,
              customModalData,
            };
        this.modalsService.pushModal(modalInfo);
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: getHistory ? 'failed-error-history-fetch' : 'failed-pipeline-error-list-fetch',
          message: `Failed to retrieve errors for the given pipeline "${pipelineName}".`,
        });
      })
      .finally(() => {
        this.updateStatus(AppState.IDLE, null);
      });
  };

  @action
  updatePipelineErrorHistory = ({ defaultTab, omitHistorical, ...pipelineId }: UpdatePipelineErrorsArgs) => {
    const pipelineInfo = generatePipelineInfoFromId(pipelineId);
    const { pipelineName, location, processType, processingId, process_name: processName } = pipelineInfo;

    const params: PipelineErrorParams = {
      processName,
      location,
      processType,
      processingId,
      openErrorsOnly: !!omitHistorical,
    };

    return this.adiApiService.axios
      .get(API.QUERY_ERROR, { params })
      .then((response: AxiosResponse) => {
        const data: PipelineErrorResponse = response.data;
        const { errors, inc_url } = data;

        // Update pipelineId if changed
        if (!isEqual(pipelineId, this.pipelineErrorPipelineId)) {
          this.setPipelineErrorPipelineId(pipelineId ?? null);
        }

        // Update saved error history
        const pipelineErrorData: PipelineErrorData = {
          ...this.pipelineErrorData, // keep existing data
          errorList: errors,
          inc_url: inc_url ?? null,
        };
        this.setPipelineErrorData(pipelineErrorData);
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: !omitHistorical ? 'failed-error-history-fetch' : 'failed-pipeline-error-list-fetch',
          message: `Failed to retrieve errors for the given pipeline "${pipelineName}".`,
        });
      })
      .finally(() => {
        this.updateStatus(AppState.IDLE, null);
      });
  };

  @action
  fetchSystemErrors = ({ isRecent, skipLoading, openErrorsOnly = true }: FetchSystemErrorsArgs = {}) => {
    if (!skipLoading) {
      this.updateStatus(AppState.LOADING, 'Fetching system errors...');
    }

    const params = {
      openErrorsOnly,
    };

    this.clearSystemErrorData();

    return this.adiApiService.axios
      .get(API.SYSTEM_ERROR_HISTORY, { params })
      .then((response: AxiosResponse) => {
        const data: SystemErrorHistoryResponse = response.data;
        const errorHistoryData: SystemErrorData = { errorList: data.errors };

        this.setSystemErrorData(errorHistoryData);

        this.modalsService.pushModal({
          id: `error_log-system-errors`,
          // TODO: ignore `isRecent` flag until we implement parameter to select error history start-date
          // title: isRecent ? 'Recent System Errors (Last 7 Days)' : 'System Error History',
          title: 'System Errors',
          message: 'a log message',
          state: AppState.SYSTEM_ERRORS,
          errorTableType: TableType.SystemErrors,
        });
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: 'failed-system-errors-fetch',
          message: 'Failed to retrieve system errors.',
        });
      })
      .finally(() => {
        if (!skipLoading) {
          this.updateStatus(AppState.IDLE, null);
        }
        // Reload has-system-error status
        this.loadSystemError();
      });
  };

  @action
  fetchAllOpenErrors = (args?: FetchAllOpenErrorsArgs) => {
    const { processType = ProcessType.Collection, skipLoading, updateOnly } = args || {};
    const loadingText = 'Fetching all open errors...';
    if (!skipLoading) {
      this.updateStatus(AppState.LOADING, loadingText);
    }
    this.clearPipelineErrorData();

    const params: PipelineErrorParams = { processType, openErrorsOnly: true };

    return this.adiApiService.axios
      .get(API.QUERY_ERROR, { params })
      .then((response: AxiosResponse) => {
        const data: PipelineErrorResponse = response.data;
        this.setPipelineErrorPipelineId(null);
        const sortedErrorList = sortByUnassigned(data.errors);
        const pipelineErrorData: PipelineErrorData = {
          errorList: sortedErrorList,
          isErrorHistory: false,
        };
        this.setPipelineErrorData(pipelineErrorData);

        if (!updateOnly) {
          const customModalData: PipelineErrorHistoryModalCustomData = {
            showAllOpenErrors: true,
            defaultTab: processType,
          };
          const modalInfo: ModalInfo = {
            id: `error_log-all_open_errors-${processType}`,
            title: `All Open Pipeline Errors`,
            state: AppState.PIPELINE_ERROR,
            errorTableType: TableType.PipelineError,
            customModalData,
          };
          this.modalsService.pushModal(modalInfo);
        }
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: 'failed-all-open-pipeline-error-list-fetch',
          message: `Failed to retrieve list of open '${processType}' errors.`,
        });
      })
      .finally(() => {
        if (!skipLoading) {
          this.updateStatus(AppState.IDLE, null);
        }
      });
  };

  @action
  fetchLogFile = (
    pipelineId: PipelineIdentifier,
    log_params: ErrorIdParameters | LogfileParameters,
    fileType: FileDownloadTypes = FileDownloadTypes.PROCESS_LOG,
    options: FetchLogFileOptions = { background: false, onError: () => {}, onFinished: () => {} }
  ): Promise<void> => {
    const { background, onError, onFinished } = options;

    if (!background) this.updateStatus(AppState.LOADING, 'Retrieving logs');

    let requestConfig: AxiosRequestConfig;
    if ((log_params as ErrorIdParameters).id) {
      const params = log_params as ErrorIdParameters;
      requestConfig = { params: { error_id: params.id } };
    } else {
      const params: ProcessingIntervalParameters = {
        ...(log_params as LogfileParameters),
        ...pipelineId,
      };
      requestConfig = { params };
    }

    const logfile_path = `${API.ARMFLOW_FILE_DOWNLOAD}/${fileType}`;
    return this.adiApiService.axios
      .get(logfile_path, requestConfig)
      .then((response: AxiosResponse) => {
        // Save log data text
        const logViewerData: LogViewerData = EMPTY_LOG_VIEWER_DATA;
        const text: string = response.data;

        logViewerData.text = text;
        logViewerData.fileType = fileType;
        logViewerData.pipelineId = pipelineId;
        if ((log_params as ProcessingIntervalParameters).date) {
          const params = log_params as Omit<ProcessingIntervalParameters, 'process_name' | 'site' | 'facility'>;
          const { processing_id, date } = params;

          logViewerData.date = DateTime.fromISO(date);

          if (processing_id) logViewerData.processingId = processing_id;
        }

        // Replace current log data
        this.setLogViewerData({ ...logViewerData });

        if (!background) this.updateStatus(AppState.IDLE, null);

        // Set log viewer to open
        if (!background) this.actionBarService.setLogViewerOpen(true);
      })
      .catch((error: unknown) => {
        this.adiApiService.handleError(error, { id: 'logfile-retrieval-error' });

        if (onError) onError();
      })
      .finally(() => {
        if (!background) this.updateStatus(AppState.IDLE, null);
        if (onFinished) onFinished();
      });
  };

  @action
  fetchTimelines = (new_mode: Mode, reason: RefreshReason) => {
    const { savedFilter } = this.storageService;
    const { visualToggles } = this.actionBarService;

    const refreshTime = DateTime.now();

    const usingAutoRefresh: boolean = reason === RefreshReason.AutoRefresh;
    const usingManualRefresh: boolean = reason === RefreshReason.Manual;
    const cancelAutoRefresh = !usingAutoRefresh && this._autoRefreshInterval;

    // Bail auto-refresh if toggle isn't active
    if (usingAutoRefresh && !visualToggles[SettingsVisualToggleKey.AUTO_REFRESH].active) return;

    // Bail auto-refresh if app is in loading state
    if (usingAutoRefresh && this.status.state === AppState.LOADING) return;

    // Cancel auto-refresh if method triggered manually
    if (cancelAutoRefresh) this.deactivateAutoRefresh();

    const filter = !usingAutoRefresh ? this.filters[new_mode] : new Filters({ mode: new_mode, filters: savedFilter });

    const { timelineStartDate, timelineEndDate } = filter;

    if (new_mode) {
      // Set loading flags before we fetch timelines
      if (!usingAutoRefresh) this.updateStatus(AppState.LOADING, 'Downloading timelines...');

      const query: string = filter.query;
      const fetch_url = createFilterURL(API.TIMELINES, timelineStartDate, timelineEndDate, query);

      const handleHashResponse = (response: AxiosResponse) => {
        const { hash } = response.data as TimelineHash;
        const replaceHash = hash !== this._dataHash;

        if (replaceHash) this._dataHash = hash;
        return replaceHash;
      };

      const handleTimelineResponse = (response: AxiosResponse) => {
        // Bail if auto-refresh has been canceled
        if (usingAutoRefresh && !this._autoRefreshInterval) return;

        // Bail if latest refresh occurred later than local
        //  - Alleviates cases where auto-refresh dispatched first but received response later
        if (this._dataLastRefreshed && refreshTime <= this._dataLastRefreshed) return;

        // Update last refreshed DateTime
        this._dataLastRefreshed = refreshTime;

        const data: TimelineJSON = response.data;

        const loadedNewData = this.loadDataToTimelines(data);
        if (usingManualRefresh) this.actionBarService.autoAdjustZoom(this.timelineData.totalDays, true);
        if (loadedNewData) this.setSortOrder(this.sortBy); // no need to sort if no new data was loaded
      };

      // Get data hash, then fetch data if not identical to local
      this.adiApiService.axios
        .get(fetch_url, { params: { getHash: true } })
        .then((response: AxiosResponse) => {
          const hashReplaced = handleHashResponse(response);
          if (!hashReplaced) {
            console.debug('hash identical. Will not fetch data');
            return;
          }
          if (hashReplaced) {
            console.debug('hash replaced. Fetching new data');
            return this.adiApiService.axios.get(fetch_url).then(handleTimelineResponse);
          }

          // If execution gets here, response should *always* be a TimelineJSON object
          return handleTimelineResponse(response);
        })
        .catch((error: unknown) => {
          this.adiApiService.handleError(error, {
            id: 'timelines-fetch-error',
            title: 'Failure Downloading Timeline Data',
          });
        })
        .finally(() => {
          if (!usingAutoRefresh) this.updateStatus(AppState.IDLE, null);

          // Reactivate auto-refresh if this method's triggered manually
          if (cancelAutoRefresh) this.activateAutoRefresh();
        });
    }
  };

  @action
  fetchPipelineIds = (processName: string, location: string, runType?: RunType): Promise<string[] | void> => {
    const runTypeQuery = runType ? `&run_type=${runType}` : '';
    const queryString = `process_name=${processName}&location=${location}${runTypeQuery}`;
    return this.adiApiService.axios
      .get(`${API.NON_RELEASED_PROCESSING_IDS}?${queryString}`)
      .then((response: AxiosResponse) => {
        const data: NonReleasedProcessingIdsResponse = response.data;
        const pipelineIds = data['processing_ids'];
        return pipelineIds;
      })
      .catch((error: unknown) => {
        this.adiApiService.handleError(error, {
          id: 'failed-fetching-processing-ids',
          title: 'Error Retrieving Processing IDs',
        });
        return [];
      });
  };

  @action
  fetchPrunableDates = (processName: string, site: string, facility: string, processingId: string) => {
    const params: PrunableDatesParams = {
      process_name: processName,
      processing_id: processingId,
      site: site,
      facility: facility,
    };

    return this.adiApiService.axios
      .get(API.PRUNABLE_DATES, { params })
      .then((response: AxiosResponse) => {
        const data: PrunableDatesResponse = response.data;
        const newStartDate = data.start_date ? DateTime.fromISO(data.start_date) : null;
        const newEndDate = data.end_date ? DateTime.fromISO(data.end_date) : null;

        return { newStartDate, newEndDate };
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: 'error-loading-prunable-dates',
          message: 'Error retrieving available prunable date range',
        });

        return { newStartDate: null, newEndDate: null };
      });
  };

  @action
  fetchRunModeDetails = (pipelineId: PipelineIdentifier) => {
    const { process_name, site, facility } = pipelineId;
    return this.adiApiService.axios
      .get(API.PROCESS_STATES, { params: { process_name, site, facility } })
      .then((response: AxiosResponse) => {
        const data: RunModeDetailsResponse = response.data;
        return data;
      })
      .catch((error: unknown) => {
        this.adiApiService.handleError(error, {
          id: 'failed-fetching-run-mode-details',
          title: 'Error Retrieving Run Mode Details',
        });
        return null;
      });
  };

  @action
  openPipelineStatesModal = (pipelineId: PipelineIdentifier) => {
    this.setProcessStatesData({ pipelineId });
    const { pipelineName } = generatePipelineInfoFromId(pipelineId);
    const pipelineModalTitle = generatePipelineStandardModalTitle(pipelineId);
    this.modalsService.pushModal({
      id: `process_states-${pipelineName}`,
      title: `Pipeline Process States`,
      // TODO: see processingId TODO in parameters (Elvis, today (2024/06/25): what did I mean by this?)
      subtitle: pipelineModalTitle,
      state: AppState.PROCESS_STATES,
    });
  };

  naturalSort(data_list: string[]): string[] {
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    return data_list.sort(collator.compare);
  }

  @action
  loadProcesses() {
    this.fetchExistingProcesses().then((processesMap) => {
      // Filter out non-ARMFlow processes (processes without a conf. file)
      const filteredProcesses = processesMap
        ? Object.fromEntries(Object.entries(processesMap).filter(([_, info]) => info.has_conf))
        : {};

      // Construct list of process types based on list
      const processTypes: Record<string, string> = {};
      for (const [key, value] of Object.entries(filteredProcesses)) {
        processTypes[key] = value.type;
      }

      // Update info map, process name, and process types lists
      this.setProcessesMap(filteredProcesses);
      this.setProcessNames(this.naturalSort(Object.keys(filteredProcesses)));
      this.setProcessTypes(processTypes);
    });
  }

  @action
  loadLocations() {
    // Download process name from ARMFlow API
    // Assign to observable list within this service
    this.adiApiService
      .axios(API.LOCATIONS)
      .then((response: AxiosResponse) => {
        const { locations } = response.data as LocationsListResponse;

        // natural sort the list, assign to this.locations
        this.setLocations(this.naturalSort(locations.map((location) => location.toUpperCase())));
        this.locationsFacilitiesOnly = filterSitesFromLocations(this.locations);
      })
      .catch((err) => {
        this.adiApiService.handleError(err, {
          id: 'error-pcm-locations-retrieval',
          message: 'Error occurred while retrieving list of locations',
        });
      });
  }

  @action
  fetchExistingProcesses() {
    return this.adiApiService.axios
      .get(API.EXISTING_PROCESSES)
      .then((response: AxiosResponse) => {
        const data: ProcessesMap = response.data;
        return data;
      })
      .catch((err) => {
        this.adiApiService.handleError(err, {
          id: 'error-existing-process-retrieval',
          message: 'Error occurred while retrieving list of existing processes',
        });
        return {} as ProcessesMap;
      });
  }

  fetchEwoUrl(): Promise<EWOUrlResponse> {
    return this.adiApiService.axios
      .get(API.GET_EWO_URL)
      .then((response: AxiosResponse) => {
        const data: EWOUrlResponse = response.data;
        return data;
      })
      .catch((err) => {
        this.adiApiService.handleError(err, {
          id: 'error-fetching-ewo-url',
          message: 'Error occurred while retrieving EWO URL from server',
        });
        return { ewo_url: null };
      });
  }

  @action
  loadUsers() {
    // Download list of usernames from ARMFlow API
    // Assign to observable list within this service
    this.adiApiService.axios
      .get(API.USERS)
      .then((response: AxiosResponse) => {
        const { users } = response.data as UsersListResponse;
        this.setUsers(this.naturalSort(users));
      })
      .catch((err) => {
        this.adiApiService.handleError(err, {
          id: 'error-loading-users-for-filter',
          message: 'Error occurred while retrieving list of users',
        });
      });
  }

  @action
  loadSystemError(reason: RefreshReason = RefreshReason.Programmatic) {
    const { visualToggles } = this.actionBarService;
    const usingAutoRefresh: boolean = reason === RefreshReason.AutoRefresh;

    // Bail auto-refresh if toggle isn't active
    if (usingAutoRefresh && !visualToggles[SettingsVisualToggleKey.AUTO_REFRESH].active) return;

    this.adiApiService.axios
      .get(API.SYSTEM_ERROR)
      .then((response: AxiosResponse) => {
        const { has_system_error } = response.data as HasSystemErrorResponse;
        this.setHasSystemError(has_system_error);
      })
      .catch((err) => {
        this.adiApiService.handleError(err, {
          id: 'error-loading-system-error',
          message: 'Error occurred while retrieving system error',
        });
      });
  }

  @action
  loadDataToTimelines(data: TimelineJSON) {
    const newData = new Timeline(data.start_date, data.end_date, data.total_pipelines, data.timelines);
    const oldData = this.timelineData;

    if (newData.equals(oldData)) {
      console.debug('timeline data is equivalent to saved');
      return false;
    }

    this.timelineData = newData;
    return true;
  }

  @action
  submitRunModeChange(
    runMode: RunMode,
    pipelineId: PipelineIdentifier,
    selectedProcessTypes: string[],
    description: string
  ) {
    const { process_name, site, facility } = pipelineId;
    const { pipelineName } = generatePipelineInfoFromId(pipelineId);

    const payload: RunModeChangePayload = {
      state: runMode,
      process_name,
      site,
      facility,
      description,
      process_types: selectedProcessTypes,
    };

    console.debug(`(${pipelineName}) Update process state to [${payload.process_types}]`);
    return this.adiApiService.axios.put(API.PROCESS_STATES, payload).catch((error: unknown) => {
      this.adiApiService.handleError(error, {
        id: `togglePipelineMode-error-${runMode}-${process_name}-${site}-${facility}`,
        title: `Cannot Change Run Mode (${pipelineName})`,
      });
    });
  }

  /** @todo Make `message` parameter optional  */
  @action
  updateStatus = (new_status: AppState, message: string | null): void => {
    // if (message === null) message = '';
    const message_contents: string = message === null ? '' : message;

    transaction(() => {
      // this.status.state = new_status;
      // this.status.message = message_contents;
      // this.status.message = message;
      this.status = {
        state: new_status,
        message: message_contents,
      };
    });
  };

  @action
  updateStatusState = (new_status: AppState): void => {
    this.status.state = new_status;
  };

  @action
  updateStatusMessage = (message: string | null): void => {
    if (message === null) message = '';
    this.status.message = message;
  };

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  @action
  autofillRunProcessForm(autofillData: RunProcessFormFields) {
    this.runProcessDialogForm = new RunProcessForm(autofillData);
  }

  @action
  autofillReleaseDataForm(autofillData: ReleaseDataFormFields) {
    this.releaseDataDialogForm = new ReleaseDataForm(autofillData);
  }

  @action
  autofillPruneDataForm(autofillData: PruneDataFormFields) {
    this.pruneDataDialogForm = new PruneDataForm(autofillData);
  }

  @action
  autofillRunBundlerForm(autofillData: RunBundlerFormFields) {
    this.runBundlerDialogForm = new RunBundlerForm(autofillData);
  }

  @action
  autofillForm<T extends ARMFlowForms>(formType: T, autofillData: ArmflowFormFieldsMap[T]) {
    switch (formType) {
      case ARMFlowForms.RUN_PROCESS:
        this.autofillRunProcessForm(autofillData as RunProcessFormFields);
        break;

      case ARMFlowForms.RELEASE_DATA:
        this.autofillReleaseDataForm(autofillData as ReleaseDataFormFields);
        break;

      case ARMFlowForms.PRUNE_DATA:
        this.autofillPruneDataForm(autofillData as PruneDataFormFields);
        break;

      case ARMFlowForms.RUN_BUNDLER:
        this.autofillRunBundlerForm(autofillData as RunBundlerFormFields);
        break;

      default:
        console.warn(`Unknown form type '${formType}'. No form data's been autofilled.`);
        break;
    }
  }

  @action
  validateForm<T extends ARMFlowForms>(formPath: API, formType: T, formData: ArmflowFormModelMap[T]) {
    this.actionBarService.setValidationLoading(true);
    this.storageService.addFormToHistory(formType, formData);
    return this.adiApiService.axios
      .post(formPath, formData)
      .then(() => {
        const apiValidation = this.adiApiService.validation[formPath];
        const noModalError = this.modalsService.topModal?.state !== AppState.ERROR;

        // Accessing form's open state inside callback in case state changes between post and then/catch (e.g. user exits form)
        const isFormStillOpen = this.actionBarService.openForms[formType];

        if (!apiValidation && noModalError && isFormStillOpen) {
          switch (formPath) {
            case API.VALIDATE_RUN_PROCESS:
              this.actionBarService.setRunProcessConfirmationOpen(true);
              break;
            case API.VALIDATE_RELEASE_DATA:
              this.actionBarService.setReleaseDataConfirmationOpen(true);
              break;
            case API.VALIDATE_PRUNE_DATA:
              this.actionBarService.setPruneDataConfirmationOpen(true);
              break;
            default:
              break;
          }
        }

        this.actionBarService.setValidationLoading(false);
      })
      .catch((error: unknown) => {
        // Accessing form's open state inside callback in case state changes between post and then/catch (e.g. user exits form)
        const isFormStillOpen = this.actionBarService.openForms[formType];
        if (isFormStillOpen) {
          this.adiApiService.handleError(error, { id: 'failed-validation', message: 'Could not validate form items' });
        }

        this.actionBarService.setValidationLoading(false);
      });
  }

  @action
  submitRunProcess(afterSubmit?: () => void) {
    const loading_modal_id = 'submitting-run-process-form';

    // Show the loading modal before submitting data
    this.modalsService.pushModal({
      id: loading_modal_id,
      title: 'Submitting job (title)',
      message: 'Submitting job',
      state: AppState.LOADING,
    });

    this.sleep(500).then(() => {
      this.adiApiService.axios
        .post(API.RUN_PROCESS, this.runProcessDialogForm)
        .then((response: AxiosResponse) => {
          /**
           * Check the form items against current filter
           *  1) ensure filter dates encapsulate dates in form
           *  2) set processName and location filters if values currently exist
           *  3) set the pipeline type (VAP/Ingest) if not checked
           */

          const filter = this.currentModeFilters;
          const { startDate, endDate, processName, locationName, processType } = this.runProcessDialogForm;

          // Override the filter start date w/ form-date if unset or if form-date is earlier
          if (startDate && (!filter.timelineStartDate || startDate < filter.timelineStartDate)) {
            filter.setTimelineStartDate(startDate);

            // Uncheck last-days field if overriding date
            if (filter.useLastNumDaysField) filter.toggleUseLastNumDaysField(false);
          }

          // Override the filter end date w/ form-date if unset or if form-date is earlier
          if (endDate && (!filter.timelineEndDate || endDate > filter.timelineEndDate)) {
            filter.setTimelineEndDate(endDate);

            // Uncheck last-days field if overriding date
            if (filter.useLastNumDaysField) filter.toggleUseLastNumDaysField(false);
          }

          if (processName && filter.processName.size > 0) filter.addProcessName(processName);
          if (locationName && filter.location.size > 0) filter.addLocation(locationName);

          // Add process type if submitted process type isn't in filters
          if (processType && !filter.processType.has(processType)) {
            filter.addType(processType);
          }

          // TODO (2023/03/24, Elvis) If nothing changed, do we want to save filters (as done within `applyFilters`)?
          this.applyFilters();
        })
        .then(afterSubmit)
        .catch((error: unknown) => {
          this.adiApiService.handleError(error, { id: 'failed-run-process', title: 'Error Running Process' });
        })
        .finally(() => this.modalsService.popModal(loading_modal_id));
    });
  }

  @action
  submitReleaseData(afterSubmit?: () => void) {
    const loading_modal_id = 'submitting-release-data-form';
    this.modalsService.pushModal({
      id: loading_modal_id,
      title: 'Releasing Data (title)',
      message: 'Releasing data',
      state: AppState.LOADING,
    });

    this.sleep(500).then(() => {
      this.adiApiService.axios
        .post(API.RELEASE_DATA, this.releaseDataDialogForm)
        .then((response: AxiosResponse) => {
          this.refresh();
        })
        .then(afterSubmit)
        .catch((error: unknown) => {
          this.adiApiService.handleError(error, { id: 'failed-release-data', title: 'Error Releasing Data' });
        })
        .finally(() => this.modalsService.popModal(loading_modal_id));
    });
  }

  @action
  submitPruneData(afterSubmit?: () => void) {
    const loading_modal_id = 'submitting-prune-data-form';
    this.modalsService.pushModal({
      id: loading_modal_id,
      title: 'Pruning Data (title)',
      message: 'Pruning data',
      state: AppState.LOADING,
    });

    this.sleep(500).then(() => {
      this.adiApiService.axios
        .post(API.PRUNE_DATA, this.pruneDataDialogForm)
        .then((response: AxiosResponse) => {
          this.refresh();
        })
        .then(afterSubmit)
        .catch((error: unknown) => {
          this.adiApiService.handleError(error, { id: 'failed-prune-data', title: 'Error Pruning Data' });
        })
        .finally(() => this.modalsService.popModal(loading_modal_id));
    });
  }

  @action
  submitRunBundler(afterSubmit?: () => void) {
    const loading_modal_id = 'submitting-run-bundler-form';
    this.modalsService.pushModal({
      id: loading_modal_id,
      title: 'Running Bundler (title)',
      message: 'Submitting Job',
      state: AppState.LOADING,
    });

    this.sleep(500).then(() => {
      this.adiApiService.axios
        .post(API.RUN_BUNDLER, this.runBundlerDialogForm)
        .then((response: AxiosResponse) => {
          this.refresh();
        })
        .then(afterSubmit)
        .catch((error: unknown) => {
          this.adiApiService.handleError(error, { id: 'failed-run-bundler', title: 'Error Running Bundler' });
        })
        .finally(() => this.modalsService.popModal(loading_modal_id));
    });
  }

  @action
  stopProcess(pipelineId: PipelineIdentifier, processingId: string) {
    this.updateStatus(AppState.LOADING, 'Fetching log list...');
    const { process_name, site, facility } = pipelineId;
    const location = `${site} ${facility}`;
    const params = { process_name, location, processing_id: processingId };
    return this.adiApiService.axios
      .post(API.STOP_PROCESS, null, { params })
      .then((response: AxiosResponse) => {
        // we're not doing anything with 'job_id' for now
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { job_id } = response.data as StopProcessResponse;
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: 'failed-to-stop-process',
          message: `Failed to stop process '${process_name}' at '${site} ${facility}' (processingId: '${processingId}')`,
        });
      })
      .finally(() => this.updateStatus(AppState.IDLE, null));
  }

  @action
  resolveError({ errorId, errorType, comment, skipLoading }: ResolveErrorArgs) {
    const url = `${API.RESOLVE_ERROR}/${errorId}`;
    const params: ResolveErrorParams = { comment };

    if (!skipLoading) {
      this.updateStatus(AppState.LOADING, `Resolving ${errorType} error`);
    }

    return this.adiApiService.axios
      .delete(url, { params })
      .then((response: AxiosResponse) => {
        console.debug(`Successfully resolved ${capitalize(errorType)} error with ID '${errorId}'`);
        return response.data as ResolveErrorResponse;
      })
      .catch((error) => {
        this.adiApiService.handleError(error, {
          id: 'failed-to-resolve-error',
          message: `Failed to resolve ${capitalize(errorType)} error with ID '${errorId}'`,
        });
      })
      .finally(() => {
        if (!skipLoading) {
          this.updateStatus(AppState.IDLE, null);
        }
      });
  }
}

/** Bubble up unassigned values to the top of the list */
function sortByUnassigned(errors: PipelineErrorResponse['errors']) {
  return [...errors].sort((a, _) => (!a.assigned_to ? -1 : 0));
}
